import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "chart.js/auto";
import "chartjs-adapter-date-fns"
import "./App.css";

function App() {
  const currentDate = new Date();
  const [data, setData] = useState({ bacon: [], eggs: [], gas: [] });
  const [startDate, setStartDate] = useState(new Date("2020-02-01"));
  const [endDate, setEndDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() , 0));
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    fetch("http://127.0.0.1:5000/api/data", { cache: "no-store" })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        const currentDate = new Date();
        const oldestDate = new Date(2020, 0, 1); // January 2020
        const latestDate = new Date(currentDate.getFullYear(), currentDate.getMonth() , 0);
        setMinDate(oldestDate);
        setMaxDate(latestDate);

        const allDates = data.bacon.concat(data.eggs, data.gas).map(item => new Date(item.timestamp));
        const uniqueDates = Array.from(new Set(allDates.map(date => {
          const normalizedDate = new Date(date.getFullYear(), date.getMonth(), 1);
          return normalizedDate.toISOString().split('T')[0];
        }))).map(date => new Date(date));
        setAvailableDates(uniqueDates);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const filterDataByDateRange = (categoryData) => {
    return categoryData.filter(item => {
      const itemDate = new Date(item.timestamp);
      const startDateWithTime = new Date(startDate);
      startDateWithTime.setMonth(startDateWithTime.getMonth() - 1);
      startDateWithTime.setHours(0, 0, 0, 0);
      const endDateWithTime = new Date(endDate);
      endDateWithTime.setHours(23, 59, 59, 999);
      return itemDate >= startDateWithTime && itemDate <= endDateWithTime;
    });
  };

  const formatChartData = (categoryData) => {
    const filteredData = filterDataByDateRange(categoryData);
    const sortedData = filteredData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    const uniqueDataMap = new Map();

    sortedData.forEach(item => {
      uniqueDataMap.set(item.timestamp, item.price);
    });

    const uniqueData = Array.from(uniqueDataMap, ([timestamp, price]) => ({ timestamp, price }));

    return {
      labels: uniqueData.map(item => item.timestamp),
      datasets: [
        {
          label: "Price",
          data: uniqueData.map(item => item.price),
          fill: false,
          borderColor: "rgba(75,192,192,1)",
          tension: 0.1
        }
    ]
    };
  };
  return (
      <div>
        <h1>Bacon, Eggs, and Gas</h1>
        <div className="date-picker-container">
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            showMonthYearPicker
            dateFormat="MMMM yyyy"
            minDate={minDate}
            maxDate={maxDate}
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            showMonthYearPicker
            dateFormat="MMMM yyyy"
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
        <CategoryChart
          title="Bacon Prices"
          data={formatChartData(data.bacon)}
          description="The average price across the US per pound of sliced bacon according to the Consumer Price Index (CPI) from the Bureau of Labor Statistics"
        />
        <CategoryChart
          title="Egg Prices"
          data={formatChartData(data.eggs)}
          description="The average price across the US per dozen eggs according to the Consumer Price Index (CPI) from the Bureau of Labor Statistics"
        />
        <CategoryChart
          title="Gas Prices"
          data={formatChartData(data.gas)}
          description="The average price across the US per gallon of unleaded regular gas according to the Consumer Price Index (CPI) from the Bureau of Labor Statistics"
        />
      </div>
    );
  }
function CategoryChart({ title, data, description }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
          tooltipFormat: 'MMM yyyy',
          displayFormats: {
            month: 'MMM yyyy'
          }
        },
        ticks: {
          autoSkip: false,
          stepSize: 1,
          maxRotation: 45,
          minRotation: 45,
          callback: function(value) {
            const date = new Date(value);
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear();
            return `${month} ${year}`;
          }
        },
        grid: {
          display: true,
          drawBorder: false,
          color: 'rgba(0, 0, 0, .1)'
        }
      },
      y: {
        ticks: {
          callback: function(value) {
            return `$${value.toFixed(2)}`;
          }
        }
      }
    }
  };

  return (
    <div className="chart-container">
      <h2>{title}</h2>
      <div className="chart-content">
        <div className="chart-description">
          <p>{description}</p>
        </div>
        <div className="chart-wrapper">
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
}
export default App;